import fa from "element-ui/src/locale/lang/fa";

export {
  introduces,
  answer
}

let introduces=[
  {
    title:'优益优加品牌书院',
    text: '优益优加书院是优益优集团旗下专注于中学名校的高端托管品牌。通过舒适的住宿房间、透明健康丰富的营养餐食、时尚舒适的自习健身空间及专业细致的导师制管理，提供孩子校园外的全方位托付。通过科技赋能，优益优加支持学生无手机的全人脸通行消费、支持家长及学生实时在专属APP查看书院的透明厨房备餐、预约并选择餐及查看学生状态；并提供学生营养、名师学霸在线答疑等针对性尊享服务。以“安全安心+”、“温馨舒适+”、“智能科技加+”，托起“健康活力+”、“学习成绩+”、“心智成长+”。优益优加西安西北工大附中书院正在火热招生、西安铁一高中全国旗舰书院即将震撼面式；西安铁一初中书院、成都石室中学旗舰书院正在建设筹备。智慧书院新时代，中学托管新标杆，优学益教，加分未来，优益优加书院欢迎您的光临。',
    contact: '联系我们：029-81875672',
    img:require('@/assets/swiper/swiper1.jpg'),
    isLeft: true,
    isWhite: false
  }
]


let answer=[
  {
    title:'随时随地、小U答疑',
    text: '小U答疑是优益优集团基于十余年教学教研沉淀，应用AI数据分析等前沿技术独立自研的实时课后服务在线答疑平台。学生可以在平台上找到各类老师和学霸，解决自己在课后作业、自习等场景中的答疑需求，通过实时音视频交流及共享白板，便捷高效地解决问题。兼职老师和学霸也可利用碎片化的时间，贡献知识获取收入。',
    img:require('@/assets/swiper/swiper5.jpg'),
    isLeft: true,
    isWhite: false
  }
]
