<template>
  <div>
    <div>
      <hight-light :is-bg="true" text="小U答疑" />
    <introduce v-for="item in answer"
               :key="item.title"
               :is-left="item.isLeft"
               :text="item.text"
               :title="item.title"
               :img="item.img"
               :is-white="item.isWhite"></introduce>
    </div>
    <div style="height:22px;background-color: #fff"></div>
    <!-- <div style="background-color: #eeeeee;">
      <Contact />
    </div> -->
  </div>
  
</template>

<script>
  import HightLight from "@/components/common/hightLight/HightLight";
  import Introduce from "@/views/about/Introduce";
  import {answer} from "@/assets/about";
  import Contact from "@/components/contact/Contact";

  export default {
    name: "Answer",
    components:{
      HightLight,
      Introduce,
      Contact
    },
    data(){
      return {
        answer
      }
    }
  }
</script>

<style scoped>

</style>
